<template>
    <div class="subpage-wrapper" v-if="token">
        <div class="subpage-container">
            <button class="close-subpage" @click="closePopup">
                <i class="fa-solid fa-xmark"></i>
            </button>
            <div class="subpage-inner">
                <div class="modal-contents">
                    <div class="side-menu">
                        <div class="sidemenu-header">선택 목록</div>
                        <div class="menu">
                            <a
                                href="#"
                                class="menu-item"
                                data_tab="tab1"
                                @click="changeTab('tab1')"
                                ><i class="fa-solid fa-piggy-bank"></i
                                >{{ langweb("fe.cashin") }}</a
                            >
                            <a
                                href="#"
                                class="menu-item"
                                data_tab="tab3"
                                @click="changeTab('tab3')"
                                ><i class="fa-solid fa-vault"></i
                                >{{ langweb("fe.cashout") }}</a
                            >
                            <a
                                href="#"
                                class="menu-item"
                                data_tab="tab4"
                                @click="changeTab('tab4')"
                                ><i class="fa-solid fa-list"></i
                                >{{ langweb("fe.history") }}</a
                            >
                            <a
                                href="#"
                                class="menu-item"
                                data_tab="tab8"
                                @click="changeTab('tab8')"
                                ><i
                                    class="fa-solid fa-money-bill-transfer"
                                ></i
                                >{{ langweb("fe.pointtoamount") }}</a
                            >
                            <a
                                href="#"
                                class="menu-item"
                                data_tab="tab5"
                                @click="changeTab('tab5')"
                                ><i class="fa-solid fa-bell"></i
                                >{{ langweb("fe.notification") }}</a
                            >
                            <a
                                href="#"
                                class="menu-item"
                                data_tab="tab6"
                                @click="changeTab('tab6')"
                                ><i class="fa-solid fa-comment-dots"></i
                                >{{ langweb("fe.chat") }}</a
                            >
                            <a
                                href="#"
                                class="menu-item"
                                data_tab="tab11"
                                @click="changeTab('tab11')"
                                ><i class="fa-solid fa-comments"></i
                                >{{ langweb("fe.sends") }}</a
                            >
                            <a
                                href="#"
                                class="menu-item"
                                data_tab="tab7"
                                @click="changeTab('tab7')"
                                ><i class="fa-solid fa-circle-info"></i
                                >{{ langweb("fe.info") }}</a
                            >
                            <a
                                href="#"
                                class="menu-item"
                                data_tab="tab9"
                                @click="changeTab('tab9')"
                                ><i
                                    class="fa-solid fa-clock-rotate-left"
                                ></i
                                >{{ langweb("fe.betting") }}</a
                            >
                            <a
                                href="#"
                                class="menu-item"
                                data_tab="tab10"
                                @click="changeTab('tab10')"
                                ><i class="fa-solid fa-gift"></i
                                >{{ langweb("fe.gift") }}</a
                            >
                        </div>
                    </div>
                    <div class="subpage-content">
                        <div class="subpagecontent-header">
                            {{ tabTitle }}
                        </div>
                        <div class="content">
                            <div
                                id="deposit"
                                class="active tab1"
                                v-if="tabSelected === 'tab1'"
                            >
                                <Cashin_city />
                            </div>
                            <div
                                id="withdraw"
                                class="active tab3"
                                v-else-if="tabSelected === 'tab3'"
                            >
                                <Cashout_city />
                            </div>
                            <div
                                id="announcement"
                                class="table-responsive active tab5"
                                v-else-if="tabSelected === 'tab5'"
                            >
                                <Notification_city />
                            </div>
                            <div
                                id="conversion"
                                class="active tab8"
                                v-else-if="tabSelected === 'tab8'"
                            >
                                <PointToAmount_city />
                            </div>
                            <div
                                id="inquiry"
                                class="table-responsive active tab6"
                                v-else-if="tabSelected === 'tab6'"
                            >
                                <Chat_city />
                                <Contact_city />
                            </div>
                            <div
                                id="note"
                                class="active tab11"
                                v-else-if="tabSelected === 'tab11'"
                            >
                                <Send_city />
                            </div>
                            <div
                                id="transaction-details"
                                class="table-responsive active tab4"
                                v-else-if="tabSelected === 'tab4'"
                            >
                                <ListCashout_city />
                            </div>
                            <div
                                id="mypage"
                                class="active tab7"
                                v-else-if="tabSelected === 'tab7'"
                            >
                                <ChangeInfo_city />
                            </div>
                            <div
                                id="betting-details"
                                class="table-responsive active tab9"
                                v-else-if="tabSelected === 'tab9'"
                            >
                                <Betting_city />
                            </div>
                            <div
                                id="coupon"
                                class="table-responsive active tab10"
                                v-else
                            >
                                <Gift_city />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="footer-section w-ba">
        <div class="container max-width-gib">
            <!--<div class="footer-top">
                <div class="mid">
                    <img src="../assets/img/logo.png">
                </div>
            </div>-->
            <div class="row">
                <hr>
                <div class="provider-logo">
                     <img src="../assets/images/ag.png" />
                    <img src="../assets/images/microgaming.png" />
                    <img src="../assets/images/evolution.png" />
                    <img src="../assets/images/pragmatic.png" />
                    <img src="../assets/images/oriental.png" />
                    <img
                        class="no-shadow"
                        src="../assets/images/sexygaming.png"
                    />
                    <img src="../assets/images/dreamgaming.png" />
                    <img src="../assets/images/cq9.png" />
                    <img src="../assets/images/bng.png" />
                    <img src="../assets/images/habanero.png" />
                    <img src="../assets/images/bgaming.png" />
                    <img src="../assets/images/stargame.png" />
                    <img src="../assets/images/evoplay.png" />
                    <img src="../assets/images/aristocrat.png" />
                    <img src="../assets/images/playngo.png" />
                    <img src="../assets/images/genesis.png" />
                </div>
            </div>
            <div class="copyright w-ba">
                <p class="mb-0 mt-3">
                    ⓒ COPYRIGHT BETTING KING ALL RIGHTS RESERVED.
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
import $ from "jquery"
import { useStore } from "vuex";
import { computed } from "vue";
import Cashin_city from "@/components/Cashin";
// import ListCashin_city from "@/components/ListCashIn";
import Cashout_city from "@/components/Cashout";
import ListCashout_city from "@/components/ListCashOut";
import Notification_city from "@/components/Notification";
import Chat_city from "@/components/ChatList";
import Contact_city from "@/components/SendChat";
import ChangeInfo_city from "@/components/ChangeInfo";
import PointToAmount_city from "@/components/PointToAmount";
import Betting_city from "@/components/Betting";
import Gift_city from "@/components/Gift";
import Send_city from "@/components/Send";

$(document).on("click", ".footer-top .left a", function () {
    $('.subpage-content .subpagecontent-header').text(`${$(this).find('span').text()}`)
})
export default {
    name: "Footer_city",
    setup() {
        const store = useStore();
        const lang = computed(() => store.state.lang);
        const lang_en = computed(() => store.state.language);
        store.dispatch("changLang");
        return {
            langweb(variable) {
                lang.value.filter(function (e) {
                    if (e.variable == variable) {
                        if (lang_en.value == "en") {
                            variable = e.en;
                        } else {
                            variable = e.kr;
                        }
                    }
                });
                return variable;
            },
            clickEn() {
                store.commit("clickEn");
            },
            clickKr() {
                store.commit("clickKr");
            }
        };
    },
    data() {
        return {
            tabSelected:'',
            tabTitle:'',
            token:false
        };
    },
    components: {
        Cashin_city,
        Cashout_city,
        ListCashout_city,
        Notification_city,
        Chat_city,
        Contact_city,
        ChangeInfo_city,
        PointToAmount_city,
        Betting_city,
        Gift_city,
        Send_city,
    },
    mounted(){
        this.token = window.localStorage.getItem("token");
    },
    methods:{
        closePopup() {
            $(".subpage-wrapper").hide();
            $("body").css("overflow", "initial");
        },
        changeTab(tab) {
            this.tabSelected = tab;
            var datatab = $("." + tab).attr("data_tab");
            if (datatab == "tab1") {
                this.set = 1;
            } else if (datatab == "tab2") {
                this.set = 2;
            } else if (datatab == "tab3") {
                this.set = 3;
            } else if (datatab == "tab4") {
                this.set = 4;
            } else if (datatab == "tab5") {
                this.set = 5;
            } else if (datatab == "tab6") {
                this.set = 6;
            } else if (datatab == "tab7") {
                this.set = 7;
            } else if (datatab == "tab8") {
                this.set = 8;
            } else if (datatab == "tab9") {
                this.set = 9;
            } else if (datatab == "tab10") {
                this.set = 10;
            } else {
                this.set = 11;
            }
        },
        showMain(event) {
            if(this.token){
                var id = Number(event.target.getAttribute("data_id"));
                if (id === 1) {
                    this.tabSelected = "tab1";
                } else if (id === 2) {
                    this.tabSelected = "tab3";
                } else if (id === 3) {
                    this.notifi = "0";
                    this.tabSelected = "tab5";
                } else if (id === 4) {
                    this.chat = "0";
                    this.tabSelected = "tab6";
                } else if (id === 5) {
                    this.tabSelected = "tab7";
                } else if (id === 6) {
                    this.tabSelected = "tab8";
                } else {
                    this.tabSelected = "tab11";
                }
                $(".popupMain").show();
                $("body").css("overflow", "hidden");
                this.toggleMobileMenu = false;
            }else{
                alert('Invalid Token')
            }
        }
    }
};
</script>
<style></style>
